.active {
	color: #e9e9e9;
	text-decoration: underline;
}
.bottomBar {
	margin-top: 150px;
}
.policiesList {
	color: #37a0dc;
	display: flex;
	flex-direction: row;
	font-family: "oblique", sans-serif;
	font-size: 1.75em;
	justify-content: space-between;
	margin: 25px auto;
	text-align: center;
	width: 33%;
}
.policiesList *:hover {
	color: #e9e9e9;
}
.policiesPage {
	display: flex;
	flex-direction: column;
	height: 100%;
	left: 250px !important;
	min-height: 100vh;
	pointer-events: all;
	position: relative;
	width: calc(100vw - 250px);
}
.policiesTitle {
	color: #e9e9e9;
	display: flex;
	flex-direction: row;
	font-family: "oblique", sans-serif;
	font-size: 4em;
	justify-content: center;
}
.policyDefault {
	margin: 15% auto;
	text-align: center;
}
.policyText {
	color: #e9e9e9;
	display: flex;
	flex-direction: column;
	font-family: "regular", sans-serif;
	font-size: 1.75em;
	justify-content: center;
	margin: 25px auto;
	overflow-y: overlay;
	width: 50%;
}
.policyText h2,
.policyText h3 {
	color: #37a0dc;
}
.topBar {
	background-color: #272a30;
	border-bottom: 1px solid #37a0dc;
	display: flex;
	flex-direction: column;
	height: 150px;
	justify-content: center;
	overflow: hidden;
	position: fixed;
	top: 0;
	width: calc(100vw - 250px);
	z-index: 1000;
}