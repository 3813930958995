.active-project {
	color: #ff8800;
}
.archived-project {
	color: #483C32;
}
.complete-project {
	color: #008000;
}
.fadeIn {
	animation: fadeIn 1s forwards;
}
.fadeOut {
	animation: fadeOut 1s forwards;
}
.fadeOut::before {
	background: linear-gradient(135deg, transparent 45%, red 45%, red 55%, transparent 55%);
	content: '';
	height: 100%;
	left: 0;
	opacity: 0.5;
	pointer-events: none;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 3;
}
.hide {
	animation: disappear .5s forwards;
}
.locked {
	font-size: 1.5em;
	padding: 5px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
}
.locked span {
	font-family: "oblique", sans-serif;
	font-weight: 100;
}
.moreInfoModal {
	animation: appear 1s;
	background-color: #1a1e23;
	border: 1px solid #37a0dc;
	bottom: 10vh;
	display: none;
	flex-direction: column;
	height: max-content;
	justify-content: center;
	left: 8%;
	margin: auto 15%;
	pointer-events: all;
	position: fixed;
	right: 8%;
	width: 65vw;
	z-index: 4;
}
.moreInfoModal button {
	align-items: center;
	background-color: #272a30;
	border-radius: 15px;
	color: #e9e9e9;
	display: flex;
	font-family: "oblique", sans-serif;
	font-size: 2em;
	height: 2.4em;
	justify-content: center;
	margin: 25px auto;
	pointer-events: all;
	width: 5em;
}
.project {
	align-items: center;
	background-color: #1a1e23;
	display: flex;
	flex-direction: column;
	height: 250px;
	justify-content: center;
	scale: 1;
	width: 100%;
}
.projectAbout {
	color: #e9e9e9;
	font-family: "oblique", sans-serif;
	font-size: 2em;
	margin: 5% 10%;
	pointer-events: all;
	text-align: center;
}
.projectDetails {
	color: #e9e9e9;
	font-family: "oblique", sans-serif;
	font-size: 1.75em;
	margin: 5% 10%;
	pointer-events: all;
	text-align: center;
}
.project img {
	background-color: #1a1e23;
	display: flex;
	flex-direction: row;
	height: auto;
	margin: auto;
	max-height: 250px;
	max-width: 430px;
	position: absolute;
	width: auto;
}
.projectLinks {
	display: flex;
	flex-direction: row;
	width: 0%;
}
.projectLinks a {
	background: linear-gradient(to right, #37A0DC 34%, #E9E9E9 67%) left;
	background-size: 300% 100%;
	color: #e9e9e9;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	font-size: 1.75em;
	justify-content: center;
	min-width: 50%;
	padding: 10px 0;
	text-align: center;
	text-decoration: none;
	transition: .5s ease-out;
}
.projectLinks a::after {
	display: none;
}
.projectLinks a:hover {
	background-position: right;
	color: #1a1e23;
}
.projectLinks a:hover::after {
	display: none;
}
.projectLinks .infoLink {
	background: linear-gradient(to right, #37A0DC 34%, #E9E9E9 67%) left;
	background-size: 300% 100%;
	color: #e9e9e9;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	font-size: 1.75em;
	justify-content: center;
	min-width: 50%;
	padding: 10px 0;
	text-align: center;
	text-decoration: none;
	transition: .5s ease-out;
}
.projectLinks .infoLink::after {
	display: none;
}
.projectLinks .infoLink:hover {
	background-position: right;
	color: #1a1e23;
}
.projectLinks .infoLink:hover::after {
	display: none;
}
.projectList {
	display: grid;
	gap: 15px;
	grid-template-columns: repeat(3, minmax(430px, 1fr));
	padding-bottom: 25px;
}
.projectName {
	color: #37a0dc;
	font-family: "oblique", sans-serif;
	font-size: 3.5em;
	padding-top: 2%;
	pointer-events: all;
	text-align: center;
}
.projectStatus {
	display: flex;
	flex-direction: row;
	gap: 10px;
	justify-content: center;
}
.project:hover > .projectLinks {
	background-color: #37a0dc;
	bottom: 0;
	display: flex;
	height: 50px;
	justify-content: center;
	opacity: 1;
	position: absolute;
	transition: all ease-in-out .5s;
	width: 100%;
	z-index: 3;
}
.project.fadeOut {
	pointer-events: none;
}
.projectTitle {
	color: #37a0dc;
	font-family: "oblique", sans-serif;
	font-size: 1.75em;
	padding: 5px;
	position: fixed;
	top: 0;
	z-index: 2;
}
.show {
	animation: appear .5s ease-in-out;
}
.starred {
	font-size: 1.5em;
	padding: 5px;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 2;
}
.starred span {
	font-family: "oblique", sans-serif;
	font-weight: 100;
}
.fa-lock {
	color: #700d0d;
}
.fa-unlock {
	color: #064706;
}
.statusLabel {
	color: #e9e9e9;
	font-family: "oblique", sans-serif;
	font-size: 2.5em;
}
.statusValue {
	color: #37a0dc;
	font-family: "oblique", sans-serif;
	font-size: 2.5em;
	pointer-events: all;
	text-align: center;
}
@keyframes disappear {
	0% {
		opacity: 100%;
		transform: scale(1);
	}
	100% {
		opacity: 0%;
		transform: scale(0);
	}
}
@keyframes fadeIn {
	0% {
		opacity: 50%;
	}
	100% {
		opacity: 100%;
	}
}
@keyframes fadeOut {
	0% {
		opacity: 100%;
	}
	100% {
		opacity: 50%;
	}
}
@media (any-pointer: coarse) {
	.moreInfoModal {
		top: 0;
		width: 90vw;
	}
	.projectList {
		display: grid;
		gap: 15px;
		grid-template-columns: repeat(2, minmax(430px, 1fr));
		padding-bottom: 25px;
	}
}