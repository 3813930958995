.filterBar {
	background: #37a0dc;
	border-radius: 50px;
	list-style: none;
	margin: 15px auto;
	text-align: center;
	width: fit-content;
}
.filterBar .active {
	color: #e9e9e9;
}
.filterBar li {
	color: #1a1e23;
	cursor: pointer;
	display: inline-block;
	font-family: "regular", sans-serif;
	font-size: 1.75em;
	line-height: 1;
	padding: 10px 15px;
	transition: all .3s ease-in-out;
}
.filterBar li:hover {
	color: #e9e9e9;
}
.filterTitle {
	color: #e9e9e9;
	font-family: "oblique", sans-serif;
	font-size: 1.75em;
	margin: 0;
	pointer-events: all;
	text-align: center;
}
.projects {
	display: flex;
	flex-direction: column;
	gap: 15px;
	justify-content: center;
	width: 100%;
}
