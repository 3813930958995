canvas {
	display: block;
}
.iconContainer img {
	display: flex;
	height: 75vh;
	left: calc(50% + 250px);
	opacity: 0.3;
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%);
	width: auto;
	z-index: 1;
}
.introContent {
	display: flex;
	flex-direction: column;
	left: 50%;
	position: absolute;
	top: 50%;
}
.myName {
	color: #e9e9e9;
	display: flex;
	flex-direction: row;
	font-family: "oblique", sans-serif;
	font-size: 4em;
	justify-content: center;
	z-index: 2;
}
.typedSkills {
	color: #37a0dc;
	display: flex;
	font-family: "oblique", sans-serif;
	font-size: 1.75em;
}
@media (any-pointer: coarse) {
	.introContent {
		left: 25%;
		top: 25%;
	}
	.myName {
		font-size: 7em;
	}
	.typedSkills {
		font-size: 3em;
	}
}
