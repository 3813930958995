.bing {
	display: flex;
	flex-direction: column;
	height: 500px;
	justify-content: center;
	margin: auto;
	width: 500px;
}
body {
	background-color: #272A30;
	background-repeat: no-repeat;
	background-size: 100%;
	display: flex;
	flex-direction: column;
	height: 100vh;
	margin: 0;
	padding: 0;
	pointer-events: all;
	width: 100vw;
}
button {
	align-self: center;
	background-color: #03D4DB;
	border-radius: 15px;
	color: #1A1E23;
	font-family: 'regular', sans-serif;
	font-size: 2em;
	margin: 50px;
	padding: 15px;
	width: 200px;
}
button:hover {
	background-color: #E9E9E9;
}
.call {
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.trackedBanner {
	color: #E9E9E9;
	display: flex;
	flex-direction: row;
	font-family: 'oblique', sans-serif;
	font-size: 3.5em;
	justify-content: center;
	text-align: center;
}
.trackedInfo {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.trackedInfo img {
	display: flex;
	flex-direction: row;
	height: 500px;
	justify-content: center;
	margin: 50px auto;
	width: 500px;
}
.trackedName {
	color: #37A0DC;
	display: flex;
	flex-direction: row;
	font-family: 'oblique', sans-serif;
	font-size: 4em;
	justify-content: center;
	margin: 50px;
}
.trackerContent {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: auto;
	width: 80%;
}
@media (pointer: coarse) {
	.bing {
		display: flex;
		flex-direction: column;
		height: 800px;
		justify-content: center;
		margin: auto 0;
		width: 100%;
	}
	button {
		font-size: 4em;
	}
	.trackedBanner {
		font-size: 4em;
		line-height: 2;
	}
	.trackedInfo {
		flex-direction: column;
	}
	.trackedInfo img {
		height: 100%;
		margin: 50px auto;
		width: 100%;
	}
}