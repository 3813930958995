.badge {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-height: 15vh;
}
.badge:hover {
    transform: scale(1.5);
}
.badges {
    color: #e9e9e9;
    display: flex;
    flex-direction: row;
    font-family: "regular", sans-serif;
    font-size: 1.75em;
    justify-content: flex-start;
}
.cert {
    display: flex;
    flex-direction: column;
    font-family: "oblique", sans-serif;
    font-size: 1.75em;
    text-decoration: 1px underline dotted #e9e9e9;
}
.cert,
.cert:hover,
.cert:visited {
    color: #e9e9e9;
}
.certProvider {
	color: #37a0dc;
    font-family: "oblique", sans-serif;
    font-size: 2em;
    padding-top: 15px;
    text-decoration: 1px underline dotted #e9e9e9;
}
.certs {
	display: flex;
	flex-direction: column;
	gap: 15px;
}
.certsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.companyName {
    color: #37a0dc;
    font-family: "oblique", sans-serif;
    font-size: 2em;
    padding-top: 15px;
    text-decoration: 1px underline dotted #e9e9e9;
}
.detail {
    color: #e9e9e9;
    font-family: "oblique", sans-serif;
    font-size: 1.75em;
    padding-left: 45px !important;
    width: 90%;
}
.detailHeader {
    color: #37a0dc;
    font-family: "regular", sans-serif;
    font-size: 2.5em;
}
.gpa {
    color: #e9e9e9;
    font-family: "regular", sans-serif;
    font-size: 1.75em;
    padding-left: 60px !important;
}
.honors {
    color: #37a0dc;
    font-family: "regular", sans-serif;
    font-size: 1.75em;
    padding-left: 60px !important;
}
.pageInfo {
    color: #e9e9e9;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-family: "regular", sans-serif;
    font-size: 2em;
    justify-content: left;
    padding: 20px 0 0 125px !important;
}
.program {
    color: #e9e9e9;
    font-family: "regular", sans-serif;
    font-size: 1.75em;
    padding-left: 45px !important;
}
.resume {
    display: flex;
    flex-direction: column;
    margin: 15px auto;
    width: 100vw;
}
.resumeDetails {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 45px;
    margin-top: 15px;
}
.resumeLeft {
    display: flex;
    flex-direction: column;
    width: 45%;
}
.resumeRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 55%;
}
.schoolName {
    color: #37a0dc;
    font-family: "oblique", sans-serif;
    font-size: 2em;
    padding-top: 15px;
    text-decoration: 1px underline dotted #e9e9e9;
}
.sectionDetails {
    border-left: 2px solid #37a0dc;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}
.sectionDetails:has(.badges) {
    border-left: none;
}
.sectionDetails::before {
    border-radius: 50px;
    content: "";
    height: 16px;
    position: absolute;
    width: 16px;
}
.sectionDetails * {
    margin-left: 8px;
    padding-left: 20px;
}
.sectionLabel {
    color: #e9e9e9;
    font-family: "regular", sans-serif;
    font-size: 2em;
    margin-top: 15px;
}
.work {
    color: #e9e9e9;
    font-family: "regular", sans-serif;
    font-size: 1.75em;
    padding-left: 45px;
    padding-top: 15px;
    width: 75%;
}
.work li {
    border-left: none;
    display: block;
    text-align: left;
}
.work li::before {
    content: "- ";
}
.years {
    color: #e9e9e9;
    font-family: "regular", sans-serif;
    font-size: 1.75em;
    padding-left: 45px;
}
@media (any-pointer: coarse) {
    .badge {
        width: 10vw;
    }
}