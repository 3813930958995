* {
	-webkit-user-drag: none;
	scroll-behavior: smooth !important;
	user-select: none;
}
::-webkit-scrollbar {
	background-color: rgba(0, 0, 0, 0);
	overflow-y: scroll;
	width: 7px;
}
::-webkit-scrollbar-corner {
	background-color: #272a30;
}
::-webkit-scrollbar-thumb:horizontal {
	background-color: #37a0dc;
	border: 0;
	min-height: 50px;
}
::-webkit-scrollbar-thumb:hover {
	background-color: #37a0dc;
}
::-webkit-scrollbar-thumb:vertical {
	background-color: #37a0dc;
	border: 0;
	min-height: 50px;
}
::-webkit-scrollbar-track:horizontal {
	background-color: rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-track-piece {
	background-color: rgba(0, 0, 0, 0);
	border: 0;
}
::-webkit-scrollbar:vertical {
	background-color: #272a30;
	overflow-y: scroll;
	width: 7px;
}
a::after {
	background-color: #37a0dc;
	bottom: 0;
	content: "";
	display: flex;
	flex-direction: row;
	height: 2px;
	left: 0;
	transform: scaleX(0);
	transition: transform .25s ease-out;
	width: auto;
}
@font-face {
	font-family: 'oblique';
	src: url('./assets/fonts/oblique.ttf') format('truetype');
}
@font-face {
	font-family: 'regular';
	src: url('./assets/fonts/regular.ttf') format('truetype');
}
@keyframes appear {
	0% {
		opacity: 75%;
		transform: scale(0);
	}
	100% {
		opacity: 100%;
		transform: scale(1);
	}
}
@keyframes fadeInOut {
	0% {
		opacity: 0;
	}
	1% {
		opacity: 1;
	}
	10% {
		opacity: 1;
	}
	85% {
		opacity: 1;
	}
	90% {
		opacity: .5;
	}
	95% {
		opacity: .25;
	}
	100% {
		opacity: .1;
	}
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	10% {
		opacity: 0;
	}
	99% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	10% {
		opacity: 1;
	}
	85% {
		opacity: 1;
	}
	100% {
		opacity: .1;
	}
}
@media (any-pointer: coarse) {
	.mainContent {
		display: block;
		flex-direction: column;
		height: 100%;
		left: 0 !important;
		pointer-events: all;
		top: 100px !important;
		width: 100%;
	}
}
@viewport {
	width: device-width;
	zoom: .35;
}
body {
	background-color: #272a30;
	display: flex;
	margin: 0;
	overflow-x: hidden;
}
img {
	opacity: 1;
	transition: all .25s;
}
img[onload] {
	opacity: 0 !important;
}
.loading-screen {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: black;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	opacity: 0;
	animation: fadeInOut 4s ease-in-out forwards;
	position: absolute;
	transform: scale(1.35);
}
.loading-container {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	left: 250px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9999;
	animation: fadeOut 4s ease-in-out forwards;
}

.loading-container img {
	width: auto;
	height: auto;
	max-height: 55vh;
	max-width: 100vw;
}
.mainContent {
	display: flex;
	flex-direction: column;
	height: 100%;
	left: 250px !important;
	min-height: 100vh;
	pointer-events: all;
	position: relative;
	width: calc(100% - 250px);
}
.moreInfoModal {
	animation: appear 1s;
	background-color: #1a1e23;
	border: 1px solid #37a0dc;
	bottom: 10vh;
	display: none;
	flex-direction: column;
	height: max-content;
	justify-content: center;
	left: 8%;
	margin: auto 15%;
	pointer-events: all;
	position: fixed;
	right: 8%;
	width: 65vw;
	z-index: 4;
}
.moreInfoModal button {
	align-items: center;
	background-color: #272a30;
	border-radius: 15px;
	color: #e9e9e9;
	display: flex;
	font-family: "oblique", sans-serif;
	font-size: 2em;
	height: 2.4em;
	justify-content: center;
	margin: 25px auto;
	pointer-events: all;
	width: 5em;
}
.moreInfoModal button:hover {
	color: #37a0dc;
}
.page {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: space-around;
	margin: 0;
	min-height: 100vh;
	padding: 0;
}
.pageContent {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: auto;
	width: 90%;
}
.pageDetails {
	color: #e9e9e9;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-family: "regular", sans-serif;
	font-size: 2em;
	justify-content: center;
	padding: 10px 0 0px 125px !important;
	width: 70%;
}
.pageHeader {
	margin: 15px auto;
	width: 100vw;
}
.pageTitle {
	color: #37a0dc;
	display: block;
	font-family: "oblique", sans-serif;
	font-size: 3em;
	padding-left: 50px;
}
.transition {
	animation: appear .5s ease-in-out;
	display: flex;
	flex-direction: column;
	flex: 1;
}
