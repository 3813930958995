.about {
	display: inline-block;
	flex-direction: row;
	text-align: left;
	vertical-align: top;
}
.about a:hover {
	text-decoration: underline;
}
.about a:visited {
	color: #37a0dc;
}
.author {
	color: #37a0dc;
	display: inline-block;
}
.email {
	text-decoration: 1px underline dotted #e9e9e9;
}
.fa-chevron-right {
	color: #37a0dc;
	display: inline-flex;
	flex-direction: row;
	font-size: 1.75em;
	justify-content: left;
}
.innerText {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}
.leftSide li {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.leftSide li .innerText {
	color: #e9e9e9;
	font-family: "regular", sans-serif !important;
}
.leftSide li .normal {
	color: #e9e9e9;
	display: flex;
	font-family: "regular", sans-serif !important;
	font-size: .75em !important;
	font-weight: 500;
	letter-spacing: 2px;
	margin: auto 0 auto 20px;
	vertical-align: middle;
}
.leftSide li strong {
	color: #e9e9e9;
	font-family: "oblique", sans-serif !important;
	font-size: 1em !important;
	font-weight: 575;
	letter-spacing: 2px;
	margin: auto 0 auto 15px;
}
.moreInfo {
	display: flex;
	flex-direction: column;
	margin: 0 0 0 50px;
}
.moreInfoDetails {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}
.moreInfoDetails .leftSide {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-left: 25px;
	margin-top: 25px;
	width: 45%;
}
.moreInfoDetails .rightSide {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-left: 25px;
	margin-top: 25px;
	width: 45%;
}
.moreInfoTitle {
	color: #37a0dc;
	display: flex;
	font-family: "oblique", sans-serif;
	font-size: 2.5em;
	margin-bottom: 15px;
}
.pageHeader {
	position: relative;
}
.profile {
	height: max-content;
}
.profile img {
	border-radius: 10px;
	filter: grayscale(0%);
	-webkit-filter: grayscale(0%);
	-webkit-transition: filter 1s ease;
	width: 10vw;
}
.profile img:hover {
	filter: grayscale(100%);
	transition: 1s ease;
	-webkit-filter: grayscale(100%);
}
.quote {
	color: #e9e9e9;
	display: inline-block;
	font-family: "oblique", sans-serif;
	font-size: 1.75em;
	margin-left: 15px;
}
.rightSide li {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}
.rightSide li .innerText {
	color: #e9e9e9;
	font-family: "regular", sans-serif !important;
}
.rightSide li .normal {
	color: #e9e9e9;
	display: flex;
	font-family: "regular", sans-serif !important;
	font-size: .75em !important;
	font-weight: 500;
	letter-spacing: 2px;
	margin: auto 0 auto 20px;
	vertical-align: middle;
}
.rightSide li strong {
	color: #e9e9e9;
	font-family: "oblique", sans-serif !important;
	font-size: 1em !important;
	font-weight: 575;
	letter-spacing: 2px;
	margin: auto 0 auto 15px;
}
.school {
	text-decoration: 1px underline dotted #e9e9e9;
}
.skill {
	color: #e9e9e9;
	font-family: "oblique", sans-serif;
	font-size: 1.75em;
	margin: 8px;
	text-decoration: 1px underline dotted #e9e9e9;
}
.skill:hover {
	color: #37a0dc;
}
.skillCategory {
	color: #37a0dc;
	display: flex;
	flex-direction: row;
	font-family: "oblique", sans-serif;
	font-size: 1.75em;
	justify-content: center;
	margin: 15px 0;
	text-decoration: underline;
	white-space: nowrap;
}
.skillChart {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin: auto 50px;
}
.skillList {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.skillTitle {
	color: #37a0dc;
	display: flex;
	flex-direction: row;
	justify-content: center;
	font-family: "oblique", sans-serif;
	font-size: 2.5em;
	margin-top: 50px;
}
.skills {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin: 25px 0 0 0;
}
.skills > * {
	width: 100%;
}
.socialButtons a {
	color: #e9e9e9;
	text-decoration: none;
}
.socialButtons a:hover {
	color: #37a0dc;
}
.socialButtons a:visited {
	color: #e9e9e9;
}
.socialButtons i {
	font-size: 1.5em;
	height: 30px;
	line-height: 30px;
	text-align: center;
	width: 30px;
}
.twoColumns {
	display: flex;
	flex-direction: row;
	justify-content: center;
	text-align: center;
}
.winterLink {
	color: #37a0dc;
	display: inline-block;
	text-decoration: 1px underline dotted #e9e9e9;
	text-decoration: none;
	vertical-align: top;
}
@media (any-pointer: coarse) {
	.pageTitle {
		display: none !important;
	}
	.profile {
		left: 25%;
		top: 5%;
	}
	.profile img {
		width: 10vw;
	}
	li .innerText .normal {
		display: block;
		text-align: right;
	}
}