.activeMenu {
	color: #37a0dc !important;
}
.avatar {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 15px auto;
}
.avatarImg {
	border: 5px solid #37a0dc;
	border-radius: 50%;
	transform: scaleX(-1);
	width: 100px;
}
.copyright {
	color: #e9e9e9;
	display: flex;
	flex-direction: column;
	font-family: "regular", sans-serif;
	font-size: 1.25em;
	justify-content: space-around;
}
.copyrightText {
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.discord {
	display: flex;
	flex-direction: column;
	margin: auto;
}
.discord a {
	display: flex;
	flex: 1;
	margin: 0;
}
.discordImg {
	filter: hue-rotate(0);
	padding: 0 0 1px 0;
	-webkit-filter: hue-rotate(0);
	-webkit-transition: filter .5s ease;
	width: 100%;
}
.discordImg:hover {
	filter: hue-rotate(320deg);
	-webkit-filter: hue-rotate(320deg);
	-webkit-transition: filter .5s ease;
}
.fade-enter {
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
}
.fade-enter-active {
	opacity: 1;
}
.fade-exit {
	opacity: 1;
	transition: opacity 0.3s ease-in-out;
}
.fade-exit-active {
	opacity: 0;
}
i.fa-solid {
	display: flex;
	flex-direction: column;
	font-size: .75em;
	justify-content: center;
}
.menuItem {
	color: #e9e9e9;
	display: flex;
	flex-direction: column;
	font-family: "oblique", sans-serif;
	font-size: 2em;
	justify-content: center;
}
.menuItem a {
	color: #e9e9e9;
	text-decoration: none;
}
.menuItem a:visited {
	color: #e9e9e9;
	text-decoration: none;
}
.menuItem a::after {
	background-color: #37a0dc;
	bottom: 0;
	content: "";
	display: flex;
	flex-direction: row;
	height: 2px;
	left: 0;
	transform: scaleX(0);
	transform-origin: left;
	transition: transform .25s ease-out;
	width: auto;
}
.menuItem a:hover {
	color: #37a0dc;
}
.menuItem a:hover::after {
	display: flex;
	flex-direction: row;
	transform: scaleX(1);
}
.menuItem > * {
	justify-content: left;
	line-height: 1.5;
	text-align: left;
	vertical-align: middle;
}
.menuItem > *:active {
	color: #37a0dc;
}
.menuItem i {
	display: flex;
	text-align: center;
	width: 50px;
}
.menuItem {
	display: flex;
	flex-direction: row;
}
.menuLink {
	display: flex;
	flex-direction: row;
	text-indent: 25px;
	width: 100%;
}
.mobileMenu {
	display: none;
}
.myNameMenu {
	color: #e9e9e9;
	display: flex;
	flex-direction: row;
	font-family: "oblique", sans-serif;
	font-size: 2.5em;
	justify-content: center;
	margin: 5px auto;
}
.policies {
	color: #e9e9e9;
	display: flex;
	flex-direction: row;
	font-family: "regular", sans-serif;
	font-size: 1em;
	justify-content: center;
	margin-bottom: 5px;
	text-decoration: none;
}
.policies:hover {
	color: #30a0dc;
}
.sidebar {
	background-color: #1a1e23;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: space-between;
	position: fixed;
	width: 250px;
}
.sidebarBottom {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: flex-end;
}
.social {
	background-color: #272a30;
	border-radius: 50%;
	font-size: 1.25em;
	padding: 10px;
}
.social:hover {
	color: #37a0dc;
}
.socialButtons {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 200px;
}
.socialLinks {
	color: #e9e9e9;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: 15px auto;
	width: 100%;
}
@media (any-pointer: coarse) {
	.menuLink {
		font-size: 2.25em;
		padding: 0 30px;
	}
	.menuLink i {
		display: none;
	}
	.menuLinks {
		display: flex;
		flex-direction: row;
	}
	.sidebar {
		flex-direction: row;
		height: auto;
		justify-content: space-around;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 3;
	}
	.sidebarTop, .socialLinks, .sidebarBottom {
		display: none;
	}
}
